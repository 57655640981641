<script>
import Navbar from '../../components/nav-login-registerV3.vue'
import ModalLoginV3 from '../../components/modalLoginV3.vue'
import ModalRegisterV3 from '../../components/modalRegisterV3.vue'
import ModalVerify from '../../components/modalVerifyCode.vue'
import ModalConfirmed from '../../components/modalConfirmed.vue'

/**
 * Login component
 */
export default {
  page: {
    title: "Shared Files",
    titleTemplate: '%s - Jaybod',
    meta: [{ name: "description", content: "Jaybod Shared Files" }]
  },
  components: { 
    Navbar,
    ModalLoginV3,
    ModalRegisterV3,
    ModalVerify,
    ModalConfirmed
  },
  data() {
    return {
      errorMessage: "sss",
      isError : false,
    };
  },
  computed: {
    item(){
        return this.$store.getters['storage/item'];
    },
    loading(){
        return this.$store.getters['storage/loading'];
    },
    isLogin() {
      return this.$store.getters['auth/user'] != null
    }
  },
  mounted(){
      // this.$ga.page('/shared')
      this.$ga.event('shared-page', 'actionx', 'labelx', 1)
      this.getFile();
  },
  methods: {
      getFile(){
        const param = this.$route.query;
        this.$store.dispatch("storage/GlobalFiles",{id : param.key,directory:param.directory})
        .catch(()=>{
            this.isError = true
        });
      },
      downloadFile(){
        const link = document.createElement("a");
        link.href = `${process.env.VUE_APP_API}/oof/files_download?key=${this.$route.query.key}&directory=${this.$route.query.directory}`;
        var ext = this.item.file_name.split('.')[0]
        // this.item.file_name.split('.')[0]
        link.setAttribute("download", this.item.file_name);
        document.body.appendChild(link);
        link.click();
      }
  }
};
</script>

<template>
<div>
  <ModalLoginV3/>
  <ModalRegisterV3/>
  <ModalVerify/>
  <ModalConfirmed/>
  <Navbar class="nav-z-idx"/>
    <div class="container-v3">
        <div class="center-content-v3">
                <div>
                    <div class="d-flex justify-content-center my-4 py-4" v-if="!isError">
                            <img src="@/assets/images/mailbox-shared.svg" alt class=" px-2 img-fluid" />
                    </div>
                    <div class="d-flex justify-content-center mt-4 pt-4" v-if="isError">
                            <img src="@/assets/images/sharednotfound.svg"  alt class=" px-2 img-fluid" />
                    </div>
                    <div v-if="isError" class="shared-notfound-v3">
                      <span class="title-maintenance-v3">This file is no longer available</span>
                      <span class="text-maintenance-v3 text-center">The file has been deleted or the link is incorrect. Ask the person who shared it for more information.</span>
                    </div>
                    <!-- <h1 class="text-center textandlogoLogin">{{isError ? 'Sorry, no file found!' : 'Shared Files'}}</h1>
                    <p  class="text-center mt-2" v-if="isError">Maybe go back and try another link or the page has moved.</p> -->
                    <div class="pt-4 justify-content-center align-item-center" v-else> 
                        <b-alert v-model="isError" variant="success" class="mt-3" dismissible>{{errorMessage}}</b-alert>
                        <div>
                            <b-alert show variant="secondary" class="d-flex bg-light-custom justify-content-between align-items-center">
                                <div class="box-name-size-v3">
                                    <div class="d-flex shared-file-v3  text-shared-file">
                                        <img :src="require(`@/assets/images/icon/${item.icon}`)" alt/>
                                        <p class="mx-1" data-toggle="tooltip" :title="item.file_name">{{item.file_name}}</p>
                                    </div>
                                    <p class="text-300-16-20-b mx-1">{{item.size}}</p>
                                </div>
                            </b-alert>
                        <div v-if="!isLogin"  class="mt-4 pt-4 d-flex justify-content-between align-items-center">
                            <!-- <router-link tag="a" :to="`/login?redirectFrom=share&key${this.$route.query.key}&directory=${this.$route.query.directory}`" class="text-400-16-24 text-blue">Sign in instead</router-link> -->
                            <b-button v-b-modal.modal-signin-v3 class="text-400-16-24 text-blue no-border-background">Sign in instead</b-button>
                            <button class="btn-download-shared-v3" @click="downloadFile()">
                                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                                <span class="text-400-16-24">Download</span>
                            </button>
                        </div>
                        <div v-else class="mt-4 pt-4 d-flex justify-content-end align-items-center">
                            <button class="btn-download-shared-v3" @click="downloadFile()">
                                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                                <span class="text-400-16-24">Download</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- end card-body -->
                <!-- end card -->
                <!-- end row -->
            <!-- end col -->
            </div>
            <div class="mt-4"></div>
            <br>
            <br>
            <br>
            <!-- end row -->
        </div>
    </div>
</div>
</template>

<style lang="scss" module></style>
